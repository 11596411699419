<script>
import { mapActions, mapMutations, mapState } from "vuex";
import MenuComponent from "@/components/menu/MenuComponent";
import MainButtonIcon from "@/components/helpers/MainButtonIcon";
import MainNotice from "@/components/helpers/MainNotice";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
    components: {
        MainButton,
        MainButtonIcon,
        MenuComponent,
        MainNotice,
    },

    data() {
        return {
            isShownMenu: false,
            isShownNotice: false,
        };
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            notification: (state) => state.notification,
            statusExportingData: (state) => state.product.statusExportingData,
        }),

        isShownTitle() {
            return this.$route?.meta?.title;
        },

        isShowTitleButtons() {
            return this.$route.name === "Products";
        },
    },

    methods: {
        ...mapActions({
            createNotification: "createNotification",
            exportDataByTemplateType: "product/exportDataByTemplateType",
        }),

        ...mapMutations(["SET_NOTIFICATION"]),

        hideMenu(classList) {
            if (classList.contains("main-layout__menu-wrapper")) {
                this.isShownMenu = false;
            }
        },

        clearNotification() {
            this.SET_NOTIFICATION(null);
        },
    },
};
</script>

<template>
    <div class="main-layout">
        <div
            class="main-layout__menu-wrapper"
            :class="{ 'main-layout__menu-wrapper_visible': isShownMenu }"
            @click="hideMenu($event.target.classList)"
        >
            <MenuComponent
                class="main-layout__menu"
                @close="isShownMenu = false"
            />
        </div>

        <div class="main-layout__header header">
            <img src="../../assets/logo.svg" alt="" />

            <!-- <div class="header__company-name">
                <p>ТСП</p>
            </div> -->

            <MainButtonIcon
                class="header__button"
                :type="'button'"
                @click="isShownMenu = true"
            >
                <span class="header__icon icon-burger-menu"></span>
            </MainButtonIcon>
        </div>

        <div class="main-layout__page-wrapper">
            <div class="notification">
                <span class="icon-warning" />
                <p v-if="false">
                    Уведомляем, что поставщик услуг по приему платежей РОКЕТ
                    ВОРК приостановил в одностороннем порядке работу с
                    юридическими лицами. По вопросу заключения договора с другим
                    поставщиком услуг, просим обратиться на почту
                    <a href="mailto:sales@rcpos.app">sales@rcpos.app</a>
                </p>
                <p>
                    Уважаемые партнёры! <br />
                    Рады сообщить, что технология RC POS теперь доступна в RC
                    CITY. <br />
                    Для подключения платежного решения в кабинете RC CITY,
                    пожалуйста, обратитесь к нашему представителю или в службу
                    поддержки по номеру телефона
                    <a href="tel:+78005005762">8 800 500 57 62</a>
                </p>
            </div>

            <div v-if="isShownTitle" class="main-layout__title title">
                <h1>{{ $route.meta.title }}</h1>

                <div v-if="isShowTitleButtons" class="title__buttons">
                    <MainButton
                        :text="'Выгрузить доступные категории'"
                        :color="'outlined'"
                        :padding="'8px 24px'"
                        :size="'medium'"
                        :loading="statusExportingData === 'LOADING'"
                        @click="
                            exportDataByTemplateType({
                                partnerId: user.current_access.context_id,
                                templateType: 'category',
                                fileType: 'xlsx_extended',
                                fileName: 'categories',
                            })
                        "
                    />
                </div>
            </div>

            <router-view />
        </div>

        <MainNotice
            v-if="notification"
            :notice-data="notification"
            :auto-close="true"
            @close="clearNotification"
        />
    </div>
</template>

<style lang="scss" scoped>
.main-layout {
    height: 100%;
    display: grid;
    grid-template-columns: 404px 1fr;

    &__page-wrapper {
        background-color: $light-second;
        padding: 72px 60px 48px;
        overflow: auto;
    }

    .title {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 48px;
        max-width: 876px;

        > h1 {
            @include title-3;
            color: $dark-primary;
        }

        &__buttons {
            margin-left: auto;
        }
    }

    &__header {
        display: none;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 0 48px;
        background: $light-primary;
    }

    .header {
        // &__company-name {
        //     text-align: left;

        //     p {
        //         color: $dark-sixth;
        //         @include title-3;
        //         font-weight: 400;
        //     }
        // }

        &__button {
            margin-left: auto;
        }

        &__icon {
            display: block;
            width: 32px;
            height: 32px;
            background-color: $dark-primary;
        }
    }
}

@media (max-width: 1440px) {
    .main-layout {
        &__page-wrapper {
            padding: 72px 48px 48px;
        }
    }
}

@media (max-width: 1280px) {
    .main-layout {
        grid-template-columns: 1fr;
        grid-template-rows: 72px 1fr;

        &__page-wrapper {
            min-width: 100%;
        }

        &__menu-wrapper {
            position: fixed;
            left: -120%;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 99999;
            background: rgba(71, 71, 71, 0.6);

            &_visible {
                left: 0;
            }
        }

        &__menu {
            max-width: 425px;
            width: 100%;
            background-color: $light-primary;
            height: 100%;
        }

        &__header {
            display: flex;
        }
    }
}

@media (max-width: 1024px) {
    .main-layout {
        grid-template-rows: 56px 1fr;

        &__header {
            padding: 0 32px;
        }

        &__page-wrapper {
            padding: 72px 32px 48px;
        }
    }
}

@media (max-width: 500px) {
    .main-layout {
        &__header {
            padding: 0 16px;
        }

        &__page-wrapper {
            padding: 32px 16px 80px;
        }

        &__title {
            margin-bottom: 32px;
            padding: 0;
        }
    }
}

@media (max-width: 425px) {
    .main-layout {
        &__header {
            gap: 4px;

            img {
                height: 36px;
            }
        }

        .title {
            flex-direction: column;
            align-items: flex-start;

            &__buttons {
                margin-left: 0;
            }
        }
    }
}

@media (max-width: 360px) {
    .main-layout {
        &__title {
            margin-bottom: 32px;
        }
    }
}

.notification {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    max-width: 1000px;
    @include body-1();
    margin: 0 0 32px;

    > span {
        display: block;
        width: 32px;
        height: 32px;
        background-color: $red;
        flex-shrink: 0;
    }

    > p {
        > a {
            color: $blue;
        }
    }
}
</style>
